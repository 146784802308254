import React from "react";
import { Button } from '@headlessui/react'

const Resume = () => {
  function handlePrint() {
    const printContents = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center transition-colors duration-300 bg-gray-900 px-4 overflow-hidden print:hidden">
      <style>
        {`
          @media print {
            @page {
              size: legal;
              margin: 0;
            }
            body {
              transform: scale(0.95);
              transform-origin: top left;
            }
          }
        `}
      </style>
      <div
        id="printable"
        className="w-full max-w-4xl bg-white rounded-md shadow-lg p-6 font-sans">
        {/* Header */}
        <div className="text-center mb-6">
          <h1 className="text-4xl font-bold text-blue-700">Rich McNinch</h1>
          <div className="text-gray-700 mt-2 text-sm">
            <p>
              801 E 15th St, Sterling, IL 61081 | 📧{" "}
              <a
                href="mailto:richmcninch@gmail.com"
                className="text-blue-500 hover:underline">
                richmcninch@gmail.com
              </a>{" "}
              | <a href="tel:8155356502">
              📞 (815) 535-6502
              </a>
            </p>
            <p>
              💻{" "}
              <a
                href="https://richmcninch.com/resume"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline">
                richmcninch.com/resume
              </a>{" "}
               | 🔗{" "}
              <a
                href="https://www.linkedin.com/in/richmcninch/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline">
                linkedin.com/in/richmcninch              
              </a>
            </p>
          </div>
        </div>

        {/* Professional Summary */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            Professional Summary
          </h2>
          <p className="text-gray-700">
            Versatile IT Support Specialist with over 25 years of experience in infrastructure monitoring, troubleshooting, and technical support. Skilled in managing complex systems across diverse enterprise environments with expertise in both Windows and Linux server operations.
          </p>
        </div>

        {/* Professional Experience */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 border-b-2 border-blue-700 pb-1 mb-4">
            Professional Experience
          </h2>

          {/* AEGON Experience */}
          <div className="job mb-6">
            <p className="job-title font-bold text-lg">Infrastructure Support Engineer</p>
            <div className="company-dates flex justify-between italic text-gray-600">
              <span>AEGON / TransAmerica, Cedar Rapids IA</span>
              <span>Sep 2023 - Dec 2024</span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Monitored and maintained distributed infrastructure health across physical and virtualized environments</li>
              <li>Led critical incident response for complex server issues, achieving 95% resolution within SLA targets</li>
              <li>Provided Tier 2 escalation support for Windows/Linux server environments, troubleshooting complex technical issues</li>
              <li>Managed server maintenance, including standard system changes, updates, and server builds/decommissions</li>
              <li>Performed incident trending and analysis to identify recurring issues and implement preventative measures</li>
              <li>Resolved alerts related to backup failures, server health, AWD/FAX systems, SNMP agents, and certificate issues</li>
              <li>Created detailed documentation for operational procedures and troubleshooting guides</li>
            </ul>
          </div>

          {/* Steel River Systems Experience */}
          <div className="job mb-6">
            <p className="job-title font-bold text-lg">IT Support Technician</p>
            <div className="company-dates flex justify-between italic text-gray-600">
              <span>Steel River Systems, Rock Falls IL</span>
              <span>Aug 2020 - Sep 2023</span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Provided end-to-end technical support for 50+ remote employees, resolving hardware and software issues</li>
              <li>Managed infrastructure for VoIP systems, remote desktop environments, and endpoint device configurations</li>
              <li>Developed custom web solutions using WordPress, PHP, and self-hosted systems with user authentication</li>
              <li>Created a company intranet with Microsoft 365 domain authentication, improving internal communications</li>
              <li>Integrated marketing technologies using AWS, Twilio, and Mautic to automate customer communications</li>
              <li>Provisioned and managed computer equipment inventory, including procurement and lifecycle management</li>
            </ul>
          </div>

          {/* The Rusty Fox Wine & Alehouse Experience */}
          <div className="job mb-6">
            <p className="job-title font-bold text-lg">Managing Partner & IT Administrator</p>
            <div className="company-dates flex justify-between italic text-gray-600">
              <span>The Rusty Fox Wine & Alehouse, Sterling IL</span>
              <span>Jan 2014 - Dec 2020</span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Managed all technical infrastructure including POS systems, network security, and digital marketing platforms</li>
              <li>Implemented and maintained local IT solutions for inventory management and customer databases</li>
              <li>Created multimedia presentations and digital marketing materials using Adobe Creative Suite and Google Slides</li>
              <li>Administered Windows-based systems and network infrastructure for business operations</li>
            </ul>
          </div>

          {/* Air Play Espresso & FADE Gear Experience */}
          <div className="job mb-6">
            <p className="job-title font-bold text-lg">IT Administrator & Operations Support</p>
            <div className="company-dates flex justify-between italic text-gray-600">
              <span>Air Play Espresso & FADE Gear, Sterling IL</span>
              <span>2010 - 2020</span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Designed and maintained company websites and e-commerce platforms</li>
              <li>Implemented VoIP service with local PBX system, reducing telecommunications costs by 35%</li>
              <li>Managed technical infrastructure while supporting daily business operations</li>
              <li>Provided first-tier customer support for both in-person and online clients</li>
              <li>Maintained and troubleshot network equipment and POS systems</li>
            </ul>
          </div>

          {/* Lightedge Solutions & Avalon Networks Experience */}
          <div className="job mb-6">
            <p className="job-title font-bold text-lg">Network Operations Center Technician</p>
            <div className="company-dates flex justify-between italic text-gray-600">
              <span>Lightedge Solutions & Avalon Networks</span>
              <span>Jan 2002 - Aug 2010</span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mt-2">
              <li>Provided Tier 2 technical support for email, web hosting, and internet connectivity issues</li>
              <li>Configured and troubleshot VoIP systems, VPN connections, and LAN/WiFi networks for business clients</li>
              <li>Diagnosed and resolved complex networking issues through remote and on-site troubleshooting</li>
              <li>Performed DSL and T1 line testing and maintenance to ensure optimal connectivity</li>
              <li>Implemented monitoring solutions to identify potential issues before they impacted services</li>
            </ul>
          </div>
        </div>

        {/* Technical Skills */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 border-b-2 border-blue-700 pb-1 mb-4">
            Technical Skills
          </h2>
          <div className="skills-container grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="skills-section">
              <h3 className="font-bold text-lg text-gray-800">IT Support & Systems</h3>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li>Ticketing Systems: ServiceNow, BigPanda</li>
                <li>Operating Systems: Windows Server, Linux (Ubuntu/Debian/FreeBSD), ChromeOS</li>
                <li>Virtualization: VMware, Docker</li>
                <li>Remote Support Tools & Methodologies</li>
                <li>System Monitoring & Maintenance</li>
                <li>POS & Inventory Management Systems</li>
              </ul>
            </div>
            <div className="skills-section">
              <h3 className="font-bold text-lg text-gray-800">Network Infrastructure</h3>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li>TCP/IP, VPN, LAN/WAN Configuration</li>
                <li>Firewall Management</li>
                <li>Network Troubleshooting</li>
                <li>VoIP Systems: 3CX, Twilio, PBX Configuration</li>
                <li>Backup Systems & Disaster Recovery</li>
              </ul>
            </div>
            <div className="skills-section">
              <h3 className="font-bold text-lg text-gray-800">Cloud & Development</h3>
              <ul className="list-disc list-inside text-gray-700 mt-2">
                <li>AWS (EC2, S3, SES), Azure, Google Cloud</li>
                <li>Web Technologies: WordPress, HTML/CSS</li>
                <li>Programming: Python, JavaScript, PHP, SQL</li>
                <li>Microsoft 365 Administration</li>
                <li>E-commerce Platform Management</li>
                <li>Digital Marketing Tools</li>
                <li>Astro, React, Node.js</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Education */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 border-b-2 border-blue-700 pb-1 mb-4">
            Education
          </h2>
          <p className="text-gray-700">
            Associate's Degree, Speech/Communications<br />
            Sauk Valley Community College, 2001
          </p>
        </div>

        {/* Certifications & Additional Roles */}
        <div>
          <h2 className="text-2xl font-semibold text-blue-700 border-b-2 border-blue-700 pb-1 mb-4">
            Additional Experience
          </h2>
          <p className="text-gray-700">
            Sterling Industrial Development Commission - Officer (Business Representative), Since 2014
          </p>
        </div>
      </div>
      <Button className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white" onClick={handlePrint}>Print</Button>
    </div>
  );
};

export default Resume;
